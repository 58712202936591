/*
 * Copyright 2024 Tridium Inc. All rights reserved.
 */

import { createSlice } from '@reduxjs/toolkit';
import { ApiStatus, IDLE_STATUS, PENDING_STATUS, FULFILLED_STATUS, REJECTED_STATUS } from '../../utils/Types';
import { ApiError } from '../../utils/niagaraHttp';
import { createAsyncThunkWithRejection } from '../../utils/niagaraStore/createAsyncThunkWithRejection';
import { getAlarmCountAsync, AlarmsCountResponse } from '../../api/alarms';

const ALARMS_KEY = 'alarms';

interface AlarmsState {
  deviceAlarmsCount: Record<string, { sourceAlarmsCount: AlarmsCountResponse[] }>;
  alarmsAPIError: ApiError | null;
  alarmsAPIStatus: ApiStatus;
}

const initialState: AlarmsState = {
  deviceAlarmsCount: {},
  alarmsAPIError: null,
  alarmsAPIStatus: IDLE_STATUS,
};

const getAlarmsCountAsync = createAsyncThunkWithRejection(
  `${ALARMS_KEY}/getAlarmsCountAsync`,
  async ({ customerId, deviceUuid }: { customerId: string; deviceUuid: string }) => {
    return getAlarmCountAsync(customerId, deviceUuid).then((alarmsCount) => {
      return alarmsCount;
    });
  }
);

const alarmsSlice = createSlice({
  name: ALARMS_KEY,
  initialState,
  reducers: {},
  extraReducers: {
    [getAlarmsCountAsync.fulfilled.type]: (state, action) => {
      const { deviceUuid } = action.meta.arg;
      state.deviceAlarmsCount[deviceUuid].sourceAlarmsCount = action.payload;
      state.alarmsAPIError = null;
      state.alarmsAPIStatus = FULFILLED_STATUS;
    },
    [getAlarmsCountAsync.pending.type]: (state, action) => {
      state.alarmsAPIError = null;
      state.alarmsAPIStatus = PENDING_STATUS;
    },
    [getAlarmsCountAsync.rejected.type]: (state, action) => {
      state.alarmsAPIError = action.payload;
      state.alarmsAPIStatus = REJECTED_STATUS;
    },
  },
});

export type AlarmsAPIState = ReturnType<typeof alarmsSlice.getInitialState>;
export const alarmsReducer = { [ALARMS_KEY]: alarmsSlice.reducer };
export const alarmsSelector = (state: { [ALARMS_KEY]: AlarmsAPIState }) => state[ALARMS_KEY];
export const alarmsActions = {
  getAlarmsCountAsync,
  ...alarmsSlice.actions,
};
