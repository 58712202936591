/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */

export async function sendRemoteLogoutRequest(token?: string) {
  if (!token) return;
  const url = `${window.ENV.REMOTE_URL_TEMPLATE?.replace(
    `${window.ENV.REMOTE_URL_TEMPLATE_DEVICEUUID_KEY}.`,
    ''
  )}/noproxy/bearer-logout`;
  await fetch(url, {
    method: 'POST',
    headers: { 'Niagara-Remote-Authorization': `Bearer ${token}` },
  });
}
