/*
 * Copyright 2024 Tridium Inc. All rights reserved.
 */
import React, { useEffect, useState } from 'react';
import { ILicense } from '../../api/dto';
import { ApiStatus } from '../../utils/Types';
import { ApiError } from '../../utils/niagaraHttp';
import { getLicensesAsync, getLicensesForDeviceReRegistrationAsync } from '../../api/getLicensesAsync';

export function useDeviceRegistration(registrationCode?: string, deviceUuid?: string) {
  const [state, update] = useState<{
    status: ApiStatus;
    licenses: ILicense[] | undefined;
    selectedLicense: ILicense | undefined;
    error: ApiError | undefined;
  }>({
    status: 'idle',
    licenses: [],
    selectedLicense: undefined,
    error: undefined,
  });

  const getLicenses = (registrationCode: string) =>
    deviceUuid
      ? getLicensesForDeviceReRegistrationAsync(registrationCode, deviceUuid)
      : getLicensesAsync(registrationCode);

  useEffect(() => {
    update({ licenses: [], status: 'pending', selectedLicense: undefined, error: undefined });

    if (!!registrationCode && registrationCode.length === 8) {
      getLicenses(registrationCode)
        .then(({ licenses }) =>
          update({
            status: 'fulfilled',
            licenses: licenses,
            selectedLicense: licenses && licenses.length === 1 ? licenses[0] : undefined,
            error: undefined,
          })
        )
        .catch((error) => update({ licenses: [], status: 'rejected', error, selectedLicense: undefined }));
    } else {
      update({ licenses: [], status: 'idle', selectedLicense: undefined, error: undefined });
    }
  }, [registrationCode]);
  return {
    ...state,
    update,
  };
}
