import { useEffect, useState, useRef, SetStateAction, Dispatch } from 'react';

export function useHistoryState<T extends Object>(init?: (() => T) | T): [T, Dispatch<SetStateAction<T>>] {
  const [state, _update] = useState<T>(() => ({
    ...(typeof init === 'function' ? init() : init),
    ...window.history.state,
  }));
  useEffect(() => {
    window.history.replaceState(state, '');
  }, [state]);
  return [
    state,
    useRef((delta: SetStateAction<T>) => {
      _update(_state => ({
        ...window.history.state,
        ...(typeof delta === 'function' ? delta(_state) : delta),
      }));
    }).current,
  ];
}
