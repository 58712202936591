/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import { NiagaraButton2, NiagaraModal } from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';
import { User } from 'oidc-client-ts';
import { useEffect } from 'react';
import { useActionBlocker } from './useActionBlocker';
import React from 'react';
import { Modal } from '@scuf/common';

export function useSessionController(
  user: User | undefined,
  logout: (remoteLogout?: boolean) => Promise<void>,
  renew: () => Promise<any>,
  expiryNotificationTimeSeconds: number
) {
  const actionBlocker = useActionBlocker();
  useEffect(() => {
    if (user?.expired) {
      actionBlocker(() => logout(false))?.();
    }
  }, [user?.expired]);
  const expiresIn = user?.expires_in ?? NaN;
  return { expiryNotificationTimeSeconds, expiresIn, logout: actionBlocker(logout), renew: actionBlocker(renew) };
}

export function SessionExpiredModal({
  expiresIn,
  renew,
  logout,
  expiryNotificationTimeSeconds,
}: ReturnType<typeof useSessionController>) {
  return (
    <NiagaraModal className='session-expired-modal' open={expiresIn <= expiryNotificationTimeSeconds} size='small'>
      <Modal.Header>
        <div className='modal-heading'>Session Expiring</div>
      </Modal.Header>
      <Modal.Content>
        {(expiresIn ?? NaN) > 0 ? (
          <div>Your session will expire in {expiresIn} seconds.</div>
        ) : (
          <div>Your session has expired.</div>
        )}
      </Modal.Content>
      <Modal.Footer>
        <div className='button-container'>
          <NiagaraButton2 type='secondary' content='Log Out' disabled={!logout} onClick={() => logout?.()} />
          <NiagaraButton2 type='primary' content='Stay Connected' disabled={!renew} onClick={() => renew?.()} />
        </div>
      </Modal.Footer>
    </NiagaraModal>
  );
}
