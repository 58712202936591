/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import { IRoleAssignmentMin, accessRoleFromRoleNumber } from './roleAssignment';
import { NOT_ASSIGNED } from './typesAndConstants';


export function assigned(roleAssignments: IRoleAssignmentMin[], authorizationPaths: string[]) {
  return roleAssignments
    .filter(({ scope }) => authorizationPaths.some(p => p.match(RegExp('^' + scope + '$', 'i'))))
    .map(accessRoleFromRoleNumber)
    .filter(x => x !== NOT_ASSIGNED);
}
