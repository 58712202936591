/*
 * Copyright 2024 Tridium Inc. All rights reserved.
 */

import { lineTooltip, commonLegendBottom, lineColumnSeriesPlotOptions } from './chartOptions';
import { lineColumnSeries } from './chartOptions/series';
import { bytesToString, convertToUnit } from './metricsUtils';
import {
  LINE_COLOR_DARK,
  LINE_COLOR_LIGHT,
  RETRIEVAL_CHART_FONT_STYLE,
  RETRIEVAL_CHART_FONT_STYLE_DARK,
  RETRIEVAL_CHART_LINE_COLOR,
  RETRIEVAL_CHART_POINT_WIDTH,
  RETRIEVAL_COLOR,
} from './constants';
import { commonLegendBottomDark } from './chartOptions/legend';

export function retrievalMetricsChartOptionsV2(
  retrievalTarget: number | undefined,
  retrievalData: number[][],
  totalValue: number | undefined,
  duration: string,
  theme: string,
): Highcharts.Options {
  const retrievalName = duration === 'Yearly' ? 'Year' : 'Month';
  const isDarkTheme = theme === 'dark';

  return {
    chart: {
      backgroundColor: isDarkTheme ? '#404040' : '#ffffff',
    },
    tooltip: lineTooltip(),
    series: lineColumnSeries(
      retrievalName,
      retrievalData,
      retrievalTarget,
      RETRIEVAL_COLOR,
      RETRIEVAL_CHART_POINT_WIDTH,
      isDarkTheme ? LINE_COLOR_DARK : LINE_COLOR_LIGHT
    ),
    plotOptions: lineColumnSeriesPlotOptions(theme),
    legend: isDarkTheme ? commonLegendBottomDark : commonLegendBottom,
    yAxis: {
      title: {
        text: `Retrieval(${convertToUnit(totalValue || 0)})`,
        style: isDarkTheme ? RETRIEVAL_CHART_FONT_STYLE_DARK : RETRIEVAL_CHART_FONT_STYLE,
      },
      labels: {
        formatter: function (this) {
          return this.axis.defaultLabelFormatter.call(this).slice(0, -1);
        },
        style: isDarkTheme ? RETRIEVAL_CHART_FONT_STYLE_DARK : RETRIEVAL_CHART_FONT_STYLE,
      },
      lineWidth: 1,
      lineColor: RETRIEVAL_CHART_LINE_COLOR,
      plotLines: [
        {
          dashStyle: 'dash',
          width: 2,
          label: {"text": bytesToString(retrievalTarget, true, 0), style: { color: isDarkTheme ? '#F0F0F0' : '#303030'}, align : 'right', x: -2, y: 15},
          color: isDarkTheme ? '#FF644C' : '#EE3124',
          value: retrievalTarget,
          zIndex: 10,
        },
      ],
    },
    xAxis: {
      type: 'datetime' as const,

      labels: {
        format: duration === 'Yearly' ? '{value:%Y}' : "{value:%b '%y}",
        style: isDarkTheme ? RETRIEVAL_CHART_FONT_STYLE_DARK : RETRIEVAL_CHART_FONT_STYLE,
      },
      lineColor: RETRIEVAL_CHART_LINE_COLOR,
      lineWidth: 1,
      tickLength: 0,
      startOnTick: false,
      endOnTick: false,
    },
  };
}
