/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import React from 'react';
import { DataTable } from '@scuf/datatable';
import { ILicense } from '../../api/dto';
import { FeatureCell } from './LicenseTableCells';
import { NiagaraDataTable } from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib/lib/table';
import './LicenseTable.scss';
interface LicenseTableProps {
  licenses: ILicense[];
  onSelect: (license: ILicense) => void;
  selected: ILicense | undefined;
}
export function LicenseTable({ licenses, onSelect, selected }: LicenseTableProps) {
  const LicenseIdRenderer = (licenses: ILicense) => {
    return (
      <>
        {licenses.licenseId === selected?.licenseId && <span id='highlighted-license' />}
        {licenses.licenseId}
      </>
    );
  };
  return (
    <NiagaraDataTable
      className='license-table'
      data={licenses}
      paginationSettings={{ totalItems: licenses.length, itemsPerPage: licenses.length }}
      onCellClick={({ field, rowData }) => {
        const allowCellClick = ['niagaraLicenseId', 'customerName', 'features'];
        allowCellClick.includes(field) && onSelect(rowData);
      }}
      showItemCountAtBottom={true}
      tableName='License'
    >
      <DataTable.Column
        field='niagaraLicenseId'
        header='License ID'
        renderer={({ rowData: license }: { rowData: ILicense }) => LicenseIdRenderer(license)}
      />
      <DataTable.Column field='customerName' header='Customer Name' />
      <DataTable.Column field='features' header='Features' renderer={({ rowData }) => <FeatureCell {...rowData} />} />
    </NiagaraDataTable>
  );
}
