/*
 * Copyright 2024 Tridium Inc. All rights reserved.
 */

import { bytesToString } from '../metricsUtils';
export function areaSeriesPlotOptionsWithMouseOverEvents(
  seriesName: string,
  hasOneDataPoint: boolean,
  color: string
): Highcharts.PlotOptions {
  let crosshair: Highcharts.SVGElement;
  let singlePointLine: Highcharts.SVGElement;

  return {
    series: {
      marker: { enabled: hasOneDataPoint },
      events: {
        afterAnimate: function (this) {
          const {
            name,
            chart: { renderer: r, plotLeft: left, plotTop: top, plotHeight: height },
            data,
          } = this;
          if (name !== seriesName || data?.length !== 1) return;
          const { plotX: x, plotY: y } = data[0];
          singlePointLine = r
            .path([
              ['M', left + (x ?? 0), top + (y ?? 0)],
              ['L', left + (x ?? 0), top + height],
            ])
            .attr({
              'stroke-width': 1,
              stroke: color,
            })
            .add();
        },
      },
      point: {
        events: {
          mouseOver: function (this) {
            if (hasOneDataPoint) return;
            const {
              series: {
                name,
                chart: { renderer: r, plotLeft: left, plotTop: top, plotHeight: height },
              },
              plotX: x,
              plotY: y,
            } = this;

            if (name === seriesName) {
              crosshair = r
                .path([
                  ['M', left + (x ?? 0), top + (y ?? 0)],
                  ['L', left + (x ?? 0), top + height],
                ])
                .attr({
                  'stroke-width': 1,
                  stroke: 'black',
                })
                .add();
            }
          },
          mouseOut: function () {
            crosshair?.destroy();
          },
        },
      },
    },
  };
}

export const stackedColumnSeriesPlotOptions: Highcharts.PlotOptions = {
  series: {
    dataLabels: {
      enabled: true,
      inside: false,
      crop: true,
      shape: 'callout',
      backgroundColor: '#FFFFFF',
      borderColor: '#00000033',
      color: '#303030',
      shadow: true,
      borderWidth: 0.5,
      borderRadius: 5,
      y: -10,
      formatter(this) {
        // only the last series (top of stack) should show the label
        return this.series.index === this.series.chart.series.length - 1
          ? bytesToString(this.point.total, false, 2)
          : undefined;
      },
    },
    states: {
      inactive: {
        opacity: 1,
      },
    },
    point: {
      events: {
        mouseOver: function (this) {
          const {
            series: {
              index: seriesIndex,
              chart: { series: chartSeries },
            },
            index: pointIndex,
          } = this;
          switch (seriesIndex) {
            case 0:
              chartSeries[1]?.data[pointIndex].setState('hover');
              break;
            case 1:
              chartSeries[0]?.data[pointIndex].setState('hover');
              break;
          }
        },
        mouseOut: function (this) {
          const {
            series: {
              chart: { series: chartSeries },
            },
            index: pointIndex,
          } = this;
          chartSeries[0]?.data[pointIndex].setState('');
          chartSeries[1]?.data[pointIndex].setState('');
        },
      },
    },
    lineWidth: 2,
  },
  column: {
    stacking: 'normal',
  },
};

export const lineColumnSeriesPlotOptions = (theme: string): Highcharts.PlotOptions => {
  return {
    series: {
      states: {
        inactive: {
          opacity: 1,
        },
      },
      point: {
        events: {
          mouseOver: function (this) {
            const {
              series: {
                index: seriesIndex,
                chart: { series: chartSeries },
              },
              index: pointIndex,
            } = this;
            switch (seriesIndex) {
              case 0:
                chartSeries[1]?.data[pointIndex].setState('hover');
                break;
              case 1:
                chartSeries[0]?.data[pointIndex].setState('hover');
                break;
            }
            this.series.chart.xAxis[0].removePlotLine('hoverLine');
            this.series.chart.xAxis[0].addPlotLine({
              color: theme === 'dark' ? 'white' : 'black',
              width: 2,
              value: this.x,
              dashStyle: 'dash',
              id: 'hoverLine',
              zIndex: 3,
            });
          },
          mouseOut: function (this) {
            const {
              series: {
                chart: { series: chartSeries },
              },
              index: pointIndex,
            } = this;
            chartSeries[0]?.data[pointIndex].setState('');
            chartSeries[1]?.data[pointIndex].setState('');
            this.series.chart.xAxis[0].removePlotLine('hoverLine');
          },
        },
      },
      lineWidth: 2,
    },
    column: {
      stacking: 'normal',
    },
  };
};
