/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import React from 'react';
import { Popup } from '@scuf/common';
import { NiagaraButton2 } from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';
import './DeviceListPopupMenu.scss';

type IDeviceListPopupMenu = {
  onShowUsage?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onReregister?: () => void;
};

export function DeviceListPopupMenu({ onShowUsage, onEdit, onDelete, onReregister }: IDeviceListPopupMenu) {
  return (
    <div className='device-more-actions-button' style={{ lineHeight: '0' }}>
      <Popup
        className='device-more-actions-popup'
        position='left center'
        hideOnScroll
        element={<NiagaraButton2 type='secondary-link' icon='MoreVertical' iconSize={18} />}
      >
        {onEdit && (
          <NiagaraButton2
            type='secondary-link'
            content='Edit'
            onClick={() => {
              onEdit();
            }}
          />
        )}
        {onShowUsage && (
          <NiagaraButton2
            type='secondary-link'
            content='Usage Metrics'
            onClick={() => {
              onShowUsage();
            }}
          />
        )}
        {onReregister && (
          <NiagaraButton2
            type='secondary-link'
            content='Re-register'
            onClick={() => {
              onReregister();
            }}
          />
        )}
        {onDelete && (
          <NiagaraButton2
            type='secondary-link'
            content='Delete'
            onClick={() => {
              onDelete();
            }}
          />
        )}
      </Popup>
    </div>
  );
}
