/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import { RoleAssignmentWithOptionalId, convertToRoleAssignment } from '../../api/dto';
import { generateCreateUpdateDelete } from './generateCreateUpdateDelete';
import { CUSTOM, IAccessRole, NOT_ASSIGNED } from '../../utils/roleAssignment/typesAndConstants';
import { removeAssignments, setAssignment } from './modifyRoleAssignments';
import { NiagaraRoleAssignment, NiagaraUserDetail, patchUserRoleAssignments } from '../../api/management';

export function updateOrganizationRoleAssignmentsAsync(
  user: NiagaraUserDetail,
  accessRole: IAccessRole
): Promise<NiagaraRoleAssignment[]> {
  if (user.userType !== 'PARTNER') {
    throw new Error(`Access could not be set to ${accessRole.roleName} for user type ${user.userType}`);
  }

  const { id: userId, organizationId, roleAssignments } = user;
  const userRoleAssignments = roleAssignments.map(convertToRoleAssignment);

  if (accessRole === CUSTOM || accessRole === NOT_ASSIGNED) {
    if (userRoleAssignments.length === 0) {
      return Promise.resolve(roleAssignments);
    }

    const roleAssignmentIds = userRoleAssignments.map(x => x.id);
    return patchUserRoleAssignments(userId, { delete: roleAssignmentIds });
  }

  const updatedRoleAssignments = setAssignment(
    userRoleAssignments,
    `/organizations/${organizationId}`,
    accessRole,
    true
  );
  const createUpdateDelete = generateCreateUpdateDelete(userRoleAssignments, updatedRoleAssignments);
  return patchUserRoleAssignments(userId, createUpdateDelete);
}

export function removeOrganizationRoleAssignmentAsync(
  user: NiagaraUserDetail,
  accessRoleToRemove: IAccessRole
): Promise<NiagaraRoleAssignment[]> {
  if (user.userType !== 'PARTNER') {
    throw new Error(`Access level ${accessRoleToRemove.roleName} could not be removed from user type ${user.userType}`);
  }

  const { id: userId, organizationId, roleAssignments } = user;
  const userRoleAssignments = roleAssignments.map(convertToRoleAssignment);

  if (accessRoleToRemove === CUSTOM || accessRoleToRemove === NOT_ASSIGNED) {
    if (userRoleAssignments.length === 0) {
      return Promise.resolve(roleAssignments);
    }

    const roleAssignmentIds = userRoleAssignments.map(x => x.id);
    return patchUserRoleAssignments(userId, { delete: roleAssignmentIds });
  }

  const updatedRoleAssignments = removeAssignments(
    userRoleAssignments,
    `/organizations/${organizationId}`,
    accessRoleToRemove
  );
  const createUpdateDelete = generateCreateUpdateDelete(userRoleAssignments, updatedRoleAssignments);
  return patchUserRoleAssignments(userId, createUpdateDelete);
}

export function updateCustomerRoleAssignmentsAsync(
  user: NiagaraUserDetail,
  accessRole: IAccessRole,
  customerId: number
): Promise<NiagaraRoleAssignment[]> {
  if (user.userType !== 'PARTNER' && user.userType !== 'CUSTOMER') {
    throw new Error(`Access could not be set to ${accessRole.roleName} for user type ${user.userType}`);
  }

  const { id: userId, userType, roleAssignments } = user;
  const userRoleAssignments = roleAssignments.map(convertToRoleAssignment);

  if (accessRole === CUSTOM || accessRole === NOT_ASSIGNED) {
    const roleAssignmentIds = userRoleAssignments
      .filter(
        ra =>
          (user.userType === 'PARTNER' &&
            new RegExp(`^/organizations/${user.organizationId}/customers/${customerId}(/.+)*$`).test(ra.scope)) ||
          (user.userType === 'CUSTOMER' && new RegExp(`^/organizations/${customerId}(/.+)*$`).test(ra.scope))
      )
      .map(x => x.id);
    if (roleAssignmentIds.length > 0) {
      return patchUserRoleAssignments(userId, { delete: roleAssignmentIds });
    }
    return Promise.resolve(roleAssignments);
  }

  const authPath =
    userType === 'PARTNER'
      ? `/organizations/${user.organizationId}/customers/${customerId}`
      : `/organizations/${customerId}`;
  const updatedRoleAssignments = setAssignment(userRoleAssignments, authPath, accessRole, true);
  const createUpdateDelete = generateCreateUpdateDelete(userRoleAssignments, updatedRoleAssignments);
  return patchUserRoleAssignments(userId, createUpdateDelete);
}

export function removeCustomerRoleAssignmentAsync(
  user: NiagaraUserDetail,
  accessRoleToRemove: IAccessRole,
  customerId: number
): Promise<NiagaraRoleAssignment[]> {
  if (user.userType !== 'PARTNER' && user.userType !== 'CUSTOMER') {
    throw new Error(`Access level ${accessRoleToRemove.roleName} could not be removed from user type ${user.userType}`);
  }

  const { id: userId, userType, roleAssignments } = user;
  const userRoleAssignments = roleAssignments.map(convertToRoleAssignment);

  if (accessRoleToRemove === CUSTOM || accessRoleToRemove === NOT_ASSIGNED) {
    const roleAssignmentIds = userRoleAssignments
      .filter(
        ra =>
          (user.userType === 'PARTNER' &&
            new RegExp(`^/organizations/${user.organizationId}/customers/${customerId}(/.+)*$`).test(ra.scope)) ||
          (user.userType === 'CUSTOMER' && new RegExp(`^/organizations/${customerId}(/.+)*$`).test(ra.scope))
      )
      .map(x => x.id);
    if (roleAssignmentIds.length > 0) {
      return patchUserRoleAssignments(userId, { delete: roleAssignmentIds });
    }
    return Promise.resolve(roleAssignments);
  }

  const authPath =
    userType === 'PARTNER'
      ? `/organizations/${user.organizationId}/customers/${customerId}`
      : `/organizations/${customerId}`;
  const updatedRoleAssignments = removeAssignments(userRoleAssignments, authPath, accessRoleToRemove);
  const createUpdateDelete = generateCreateUpdateDelete(userRoleAssignments, updatedRoleAssignments);
  return patchUserRoleAssignments(userId, createUpdateDelete);
}

export function updateRoleAssignmentsAsync(
  user: Pick<NiagaraUserDetail, 'roleAssignments' | 'id'>,
  roleAssignments: RoleAssignmentWithOptionalId[]
) {
  const userRoleAssignments = user.roleAssignments.map(convertToRoleAssignment);
  return patchUserRoleAssignments(user.id, generateCreateUpdateDelete(userRoleAssignments, roleAssignments));
}
