/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import React, { PropsWithChildren, ReactNode } from 'react';
import './MessageContainer.scss'

export default function MessageContainer({
  title, message, children,
}: PropsWithChildren<{
  title: string;
  message: ReactNode;
}>) {
  return (
    <div className='message-box'>
      <div className='title'>{title}</div>
      <div className='message'>{message}</div>
      {children}
    </div>
  );
}
