/*
 * Copyright 2024 Tridium Inc. All rights reserved.
 */

export function areaSeries(
  name: string,
  data: number[][],
  target: number | undefined,
  color: string
): Highcharts.SeriesAreaOptions[] {
  if (target === undefined) {
    return [
      {
        type: 'area',
        name,
        data,
        color,
        threshold: 0,
        zones: [{ className: 'under-target', color }],
      },
    ];
  }

  return [
    {
      type: 'area',
      name,
      data,
      color,
      threshold: 0,
      zones: [
        { className: 'under-target', color, value: target },
        { className: 'above-target', color: '#ee3124' },
      ],
    },
    {
      type: 'area',
      name: 'Above Target',
      color: '#ee3124',
    },
  ];
}

export function columnSeries(
  name: string,
  data: number[][],
  target: number | undefined,
  color: string,
  pointWidth: number
): Highcharts.SeriesColumnOptions[] {
  if (target === undefined) {
    return [
      {
        type: 'column',
        name,
        data,
        color,
        pointWidth,
      },
    ];
  }

  return [
    {
      type: 'column',
      name,
      data: data.map(([date, value]) => [date, Math.min(value, target)]),
      color,
      pointWidth,
    },
    {
      type: 'column',
      name: 'Above Target',
      data: data.map(([date, value]) => [date, Math.max(value - target, 0)]),
      color: '#ee3124',
      pointWidth,
    },
  ];
}

export function lineColumnSeries(
  name: string,
  data: number[][],
  target: number | undefined,
  color: string,
  pointWidth: number,
  lineColor: string
): (Highcharts.SeriesColumnOptions | Highcharts.SeriesLineOptions)[] {
  if (target === undefined) {
    return [
      {
        type: 'column',
        name,
        data,
        color,
        pointWidth,
      },
    ];
  }
  var data2 = data.map((arr) => arr.slice());
  return [
    {
      type: 'column',
      name,
      legendSymbol: 'rectangle',
      data: data.map(([date, value]) => [date, value]),
      color,
      pointWidth,
      borderWidth: 0,
    },
    {
      type: 'line',
      name: 'Total',
      legendSymbol: 'rectangle',
      data: data2.map(([date, value], i, array) => {
        return [date, (array[i][1] += array[i - 1] && array[i - 1][1] ? array[i - 1][1] : 0)];
      }),
      color: lineColor,
    },
  ];
}
