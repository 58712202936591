/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import { AxiosInstance } from 'axios';
import {
  addAuthorizationHeader,
  defaultFulfilledResponseInterceptor,
  defaultRejectedRequestInterceptor,
  reauthenticateRetryOn401,
} from './authorizationInterceptorFunctions';

export function setupInterceptors(instance: AxiosInstance, getAccessToken: () => Promise<string>) {
  const req = instance.interceptors.request.use(
    c => addAuthorizationHeader(getAccessToken, c),
    defaultRejectedRequestInterceptor
  );
  const res = instance.interceptors.response.use(defaultFulfilledResponseInterceptor, e =>
    reauthenticateRetryOn401(instance.request, getAccessToken, e)
  );
  return () => {
    instance.interceptors.request.eject(req);
    instance.interceptors.response.eject(res);
  };
}
