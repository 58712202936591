/*
 * Copyright 2024 Tridium Inc. All rights reserved.
 */
import moment from 'moment';
import React from 'react';
import { ISubscription } from '../../api/subscription';
import './SubscriptionInfo.scss';
import _ from 'lodash';
interface SubscriptionInfoProps {
  subscriptions: ISubscription[];
}
export function SubscriptionInfo({ subscriptions }: SubscriptionInfoProps) {
  const subscription = _.find(subscriptions, { offeringCode: 'NDS' });
  return (
    <>
      <div className='nds-subscription'>
        <span>Start Date</span>
        <span className='subscription-data'>{moment(subscription?.startDate).format('DD-MMM-YYYY')}</span>
      </div>
      <div className='nds-subscription'>
        <span>End Date</span>
        <span className='subscription-data'>{moment(subscription?.endDate).format('DD-MMM-YYYY')}</span>
      </div>
    </>
  );
}
