/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */

import React from 'react';
import { getAnnualOptions, getMonthlyOptions } from './retrievalMetricsChartOptions';
import { NiagaraChart } from '../../lazyLoad/NiagaraChartLazy';
import { containerProps } from './chartOptions';
import { MetricsDuration } from '../../api/usage';

export interface IRetrievalMetricsChart {
  target: number | undefined;
  data: number[][];
  duration: MetricsDuration;
  shouldScrollIntoView: () => boolean;
}

export function RetrievalMetricsChart({ target, data, duration, shouldScrollIntoView }: IRetrievalMetricsChart) {
  const name = 'Retrieval';
  const color = '#0098b0';
  const options =
    duration === 'Annually'
      ? getAnnualOptions(data, target, name, color, shouldScrollIntoView)
      : getMonthlyOptions(data, target, name, color, shouldScrollIntoView);
  options.legend = { enabled: false };
  // forces new chart instance when options changes so that highcharts behaves correctly
  const Chart = () => <NiagaraChart options={options} containerProps={containerProps} />;
  return  <Chart />;
}