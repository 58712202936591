/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import {
  NiagaraButton2,
  NiagaraModal,
  NiagaraSelect,
} from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';
import { IOption } from '@scuf/common/dist/components/Select/ISelectProps';
import { Modal } from '@scuf/common';
import './UsageMetricsModal.scss';
import { RetrievalMetricsChart } from './RetrievalMetricsChart';
import { retrievalMetricsProgressInfoProps } from './usageMetricsProgressInfoProps';
import { storageMetricsChartOptions } from './storageMetricsChartOptions';
import { NiagaraChart } from '../../lazyLoad/NiagaraChartLazy';
import {
  IMetricsDataV2,
  getMetricsData,
  METRICS_DURATION_ANNUALLY,
  METRICS_DURATION_MONTHLY,
  METRICS_ID_RETRIEVAL,
  METRICS_ID_STORAGE,
  OFFERING_CODE_NDS,
  MetricsDuration,
} from '../../api/usage';
import { Loader } from '../../utils/Loader';
import { containerProps } from './chartOptions';
import { DeviceWithProjectId } from '../DeviceWithProjectId';
import { useTagScreen } from '../../utils/localytics/useAnalytics';
import { MetricsInfo } from './MetricsInfo';
import { MetricsBar } from './MetricsBar';

interface IUsageMetricsModal {
  device: Pick<DeviceWithProjectId, 'deviceUuid' | 'projectName' | 'deviceName' | 'hostId'>;
  onClose: () => void;
}

const METRICS_DURATION_DEFAULT = METRICS_DURATION_ANNUALLY;
const USAGE_OPTIONS: IOption[] = [
  { text: 'Annual Subscription Usage', value: METRICS_DURATION_ANNUALLY },
  { text: 'Subscription Month Usage', value: METRICS_DURATION_MONTHLY },
];

export function UsageMetricsModal({
  onClose,
  device: { deviceUuid, projectName, deviceName, hostId },
}: IUsageMetricsModal) {
  const [isStorageLoading, setIsStorageLoading] = useState(false);
  const [isRetrievalLoading, setIsRetrievalLoading] = useState(false);
  const [storageDuration, setStorageDuration] = useState<MetricsDuration>(METRICS_DURATION_DEFAULT);
  const [retrievalDuration, setRetrievalDuration] = useState<MetricsDuration>(METRICS_DURATION_DEFAULT);
  const [previousRetrievalDuration, setPreviousRetrievalDuration] = useState(retrievalDuration);
  const [monthlyStorageMetricsData, setMonthlyStorageMetricsData] = useState<IMetricsDataV2>();
  const [annualStorageMetricsData, setAnnualStorageMetricsData] = useState<IMetricsDataV2>();
  const [monthlyRetrievalMetricsData, setMonthlyRetrievalMetricsData] = useState<IMetricsDataV2>();
  const [annualRetrievalMetricsData, setAnnualRetrievalMetricsData] = useState<IMetricsDataV2>();

  useEffect(() => {
    if (annualStorageMetricsData && storageDuration === METRICS_DURATION_ANNUALLY) return;
    if (monthlyStorageMetricsData && storageDuration === METRICS_DURATION_MONTHLY) return;
    setIsStorageLoading(true);
    getMetricsData(deviceUuid, storageDuration, METRICS_ID_STORAGE, OFFERING_CODE_NDS)
      .then(storageDuration === METRICS_DURATION_ANNUALLY ? setAnnualStorageMetricsData : setMonthlyStorageMetricsData)
      .catch(/* TODO */)
      .finally(() => setIsStorageLoading(false));
  }, [storageDuration, annualStorageMetricsData, monthlyStorageMetricsData, deviceUuid]);

  useEffect(() => {
    if (annualRetrievalMetricsData && retrievalDuration === METRICS_DURATION_ANNUALLY) return;
    if (monthlyRetrievalMetricsData && retrievalDuration === METRICS_DURATION_MONTHLY) return;
    setIsRetrievalLoading(true);
    getMetricsData(deviceUuid, retrievalDuration, METRICS_ID_RETRIEVAL, OFFERING_CODE_NDS)
      .then(
        retrievalDuration === METRICS_DURATION_ANNUALLY ? setAnnualRetrievalMetricsData : setMonthlyRetrievalMetricsData
      )
      .catch(/* TODO */)
      .finally(() => setIsRetrievalLoading(false));
  }, [retrievalDuration, annualRetrievalMetricsData, monthlyRetrievalMetricsData, deviceUuid]);

  const storageData =
    storageDuration === METRICS_DURATION_ANNUALLY ? annualStorageMetricsData : monthlyStorageMetricsData;
  const retrievalData =
    retrievalDuration === METRICS_DURATION_ANNUALLY ? annualRetrievalMetricsData : monthlyRetrievalMetricsData;

  useTagScreen('Usage Metrics');

  const retrievalMetrics = retrievalMetricsProgressInfoProps(retrievalData?.summaryValue, retrievalData?.totalValue);

  return (
    <NiagaraModal className='usage-metrics-modal' open={true} size='large'>
      <div className='scrollable-modal'>
        <Modal.Header>
          <div>
            <div className='modal-label'>{projectName}</div>
            <div>
              <span className='modal-heading'>{deviceName}</span>
              <span className='host-id'>({hostId})</span>
            </div>
          </div>
          <NiagaraButton2
            type='secondary-link'
            icon='Close'
            iconColor='#606060'
            onClick={() => {
              setStorageDuration(METRICS_DURATION_DEFAULT);
              setRetrievalDuration(METRICS_DURATION_DEFAULT);
              setAnnualStorageMetricsData(undefined);
              setMonthlyStorageMetricsData(undefined);
              setAnnualRetrievalMetricsData(undefined);
              setMonthlyRetrievalMetricsData(undefined);
              onClose();
            }}
          />
        </Modal.Header>
        <Modal.Content>
          <div className='scroll-window'>
            <div className='storage-metrics'>
              {isStorageLoading ? (
                <Loader className='usage-metrics-loader' />
              ) : (
                <>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: '24px',
                    }}
                  >
                    <div className='usage-metrics-section-title'>Storage Metrics</div>
                    <NiagaraSelect options={USAGE_OPTIONS} onChange={setStorageDuration} value={storageDuration} />
                  </div>
                  <NiagaraChart
                    containerProps={containerProps}
                    options={storageMetricsChartOptions(
                      storageData?.totalValue,
                      storageData?.data ?? [],
                      storageDuration
                    )}
                  />
                </>
              )}
            </div>
            <div className='retrieval-metrics'>
              {isRetrievalLoading ? (
                <Loader className='usage-metrics-loader' />
              ) : (
                <>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: '24px',
                    }}
                  >
                    <div>
                      <div className='usage-metrics-section-title'>Retrieval Metrics</div>
                      <MetricsInfo {...retrievalMetrics}>
                        <MetricsBar {...retrievalMetrics} />
                      </MetricsInfo>
                    </div>
                    <NiagaraSelect options={USAGE_OPTIONS} onChange={setRetrievalDuration} value={retrievalDuration} />
                  </div>
                  <RetrievalMetricsChart
                    target={retrievalData?.totalValue}
                    data={retrievalData?.data ?? []}
                    shouldScrollIntoView={() => {
                      const durationChanged = retrievalDuration !== previousRetrievalDuration;
                      setPreviousRetrievalDuration(retrievalDuration);
                      return durationChanged;
                    }}
                    duration={retrievalDuration}
                  />
                </>
              )}
            </div>
          </div>
        </Modal.Content>
      </div>
    </NiagaraModal>
  );
}
