/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import { RoleAssignmentWithRoleNumber } from '../../api/dto';
import {
  IAccessRole,
  PrioritizedRoleOptions,
  AllRoleOptions,
  NOT_ASSIGNED,
  CUSTOM,
  IAssignableAccessRole,
  roleTypeOrdering,
} from './typesAndConstants';
import { IPreAuthorization, getPreAuthorization } from './getPreAuthorization';

// TODO: function names are confusing (which is which priority??)
// Consider converting objects above to classes with inbuilt comparison function
export function isDifferentRoleAndEqualPriority(a: IAccessRole, b: IAccessRole) {
  return (
    a !== b &&
    PrioritizedRoleOptions.findIndex(o => o.includes(a)) === PrioritizedRoleOptions.findIndex(o => o.includes(b))
  );
}
export function isHigherPriority(a: IAccessRole, b: IAccessRole) {
  return PrioritizedRoleOptions.findIndex(o => o.includes(a)) < PrioritizedRoleOptions.findIndex(o => o.includes(b));
}
export function isDifferentRoleAndHigherOrSamePriority(a: IAccessRole, b: IAccessRole) {
  return (
    a !== b &&
    PrioritizedRoleOptions.findIndex(o => o.includes(a)) <= PrioritizedRoleOptions.findIndex(o => o.includes(b))
  );
}
export function getRoleOptionFromRoleNumber(roleNumber: string | undefined) {
  return AllRoleOptions.filter(x => x.roleNumber !== undefined).find(x => x.roleNumber === roleNumber) ?? NOT_ASSIGNED;
}

export type IRoleAssignmentMin = Pick<RoleAssignmentWithRoleNumber, 'scope' | 'roleNumber'>;
export function getAuthorization(roleAssignments: IRoleAssignmentMin[], authorizationPaths: string[]) {
  const preAuth = getPreAuthorization(roleAssignments, authorizationPaths);
  let _overall: IAccessRole[] | undefined = undefined;
  let _effective: IAccessRole[] | undefined = undefined;
  return {
    get overall() {
      return _overall ?? (_overall = overall(preAuth));
    },
    get effective() {
      return _effective ?? (_effective = effective(preAuth));
    },
  };
}

function effective(auth: IPreAuthorization) {
  // TODO - return a set
  return Array.from(new Set([...auth.internal, ...auth.assigned, ...auth.inherited])).sort(
    (a, b) =>
      (roleTypeOrdering.get(a) ?? roleTypeOrdering.keys.length) -
      (roleTypeOrdering.get(b) ?? roleTypeOrdering.keys.length)
  );
}
function overall({ inherited, assigned, internal }: IPreAuthorization) {
  // TODO - return a set
  if (inherited.length === 0 && assigned.length === 0 && internal.length > 0) return [CUSTOM];
  return Array.from(new Set([...inherited, ...assigned]));
}

export function accessRoleFromRoleNumber(
  role: string | undefined | Pick<RoleAssignmentWithRoleNumber, 'roleNumber'>
): IAssignableAccessRole {
  if (role === undefined) {
    return NOT_ASSIGNED;
  }
  if (typeof role === 'object') {
    return accessRoleFromRoleNumber(role.roleNumber);
  }
  return AllRoleOptions.find(x => x.roleNumber === role) ?? NOT_ASSIGNED;
}
