/*
 * Copyright 2024 Tridium Inc. All rights reserved.
 */
import React from 'react';
import './DarkMode.scss';
import './DataTableDarkMode.scss';
import './DarkModeNiagaraSelect.scss';
import './SidebarDarkMode.scss';
import { NiagaraToggle } from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';
import { useTheme } from './utils/Theme/ThemeProvider';

const DarkMode = () => {
  const { toggleTheme, theme } = useTheme();

  return (
    <div className='toggle-theme-wrapper'>
      <NiagaraToggle disabled={false} checked={theme === 'dark'} onChange={toggleTheme} />
    </div>
  );
};

export default DarkMode;
