/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */

import React, { ReactNode } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Logout } from './Logout';
import { RemoteErrorPageLazy, AuthorizationErrorPageLazy } from './lazyLoad/PageComponents';
import { Callback } from './utils/Authentication/Callback';

export function PublicRoutes({ children }: { children: ReactNode }) {
  return (
    <Routes>
      <Route path='callback' element={<Callback />} />
      <Route path='logout' element={<Logout />} />
      <Route path='errors/remote/*'>
        <Route path=':error' element={<RemoteErrorPageLazy />} />
        <Route path='*' element={<RemoteErrorPageLazy />} />
      </Route>
      <Route path='errors/authorization' element={<AuthorizationErrorPageLazy />} />
      <Route path='*' element={children} />
    </Routes>
  );
}
