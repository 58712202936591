/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import axios from 'axios';
import { getAccessToken } from '../../Authentication/Oidc';
import { setupInterceptors } from './setupInterceptors';

const instance = axios.create();
setupInterceptors(instance, getAccessToken);
export default instance;
