/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import React, { useState } from 'react';
import {
  ActiveEffectButton,
  NiagaraButton2,
  NiagaraTooltip,
} from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';
import { Popup } from '@scuf/common';
import './DataServicesPopupMenu.scss';

export type IDataServicesPopupMenu = {
  setDataReporting?: () => void;
  setAlarms?: () => void;
};
export function DataServicesPopupMenu({
  setDataReporting,
  setAlarms,
}: {
  setDataReporting?: () => void;
  setAlarms?: () => void;
}) {
  const [activeClass, setActiveClass] = useState<string>();
  return !setDataReporting && !setAlarms ? null : (
    <div className={`${activeClass} data-services-button`} style={{ lineHeight: '0' }}>
      <Popup
        className='data-services-popup'
        position='bottom right'
        hideOnScroll
        element={
          <NiagaraTooltip
            className={`${activeClass} alternate-tooltip`}
            content='Data Services'
            position='bottom center'
            element={
              <ActiveEffectButton
                className={activeClass}
                type='primary-link'
                icon='Trend'
                iconSize={18}
                onClick={setAlarms && setDataReporting ? undefined : setAlarms ?? setDataReporting}
              />
            }
          />
        }
      >
        <Sentinel>{v => setActiveClass(v ? 'active-nds' : ' ')}</Sentinel>
        {setDataReporting && (
          <NiagaraButton2 type='secondary-link' content='Data Reporting' onClick={() => setDataReporting()} />
        )}
        {setAlarms && <NiagaraButton2 type='secondary-link' content='Alarms' onClick={() => setAlarms()} />}
      </Popup>
    </div>
  );
}

function Sentinel({ children }: { children: (_: boolean) => void }) {
  React.useEffect(() => {
    children(true);
    return () => children(false);
  }, []);
  return null;
}
