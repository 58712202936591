/*
 * Copyright 2024 Tridium Inc. All rights reserved.
 */
import React, { ReactNode, useState } from 'react';
import { NiagaraButton2 } from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';
import { SidebarLayout } from '@scuf/common';
import './CollapsibleSidebar.scss';
export default function CollapsibleSideBar({
   leftBar,
   mainView,
   defaultCollapsed = false,
}: {
   leftBar: ReactNode;
   mainView: ReactNode;
   defaultCollapsed: boolean;
}) {
   const [collapsed, setCollapsed] = useState(defaultCollapsed);
   return (
      <>
         <SidebarLayout collapsed={collapsed} noIcons={true}>
            <SidebarLayout.Sidebar>
               <div className='collapsible-panel'>{leftBar}</div>
            </SidebarLayout.Sidebar>
            <div className='sidebar-toggle'>
               <NiagaraButton2
                  className='toggle-btn'
                  iconColor='#707070'
                  iconSize={12}
                  icon={collapsed ? 'CaretRight' : 'CaretLeft'}
                  type='tertiary'
                  onClick={() => setCollapsed(!collapsed)}
               />
            </div>
            <div className='main-view'>{mainView}</div>
         </SidebarLayout>
      </>
   );
}
