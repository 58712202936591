/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { signinCallback } from './Oidc';
import { isSigninCallback } from './Oidc/PageStateFunctions';

export function Callback() {
  const _isSigninCallback = isSigninCallback();
  useEffect(() => {
    _isSigninCallback && signinCallback();
  }, []);
  return _isSigninCallback ? null : <Navigate replace to='/' />;
}
