/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import { useEffect, useState } from 'react';
import { getUser } from '.';
import { onUserChange } from './onUserChange';
import { User } from 'oidc-client-ts';

export function useOidcUser() {
  const [user, setUser] = useState<void | User | null>();
  useEffect(() => {
    let cancelled = false;
    getUser().then(_user => (cancelled ? undefined : setUser(_user)));
    return () => void (cancelled = true);
  }, []);
  useEffect(() => onUserChange(setUser), []);
  return user ?? undefined;
}


