/*
 * Copyright 2024 Tridium Inc. All rights reserved.
 */
import React from 'react';
export function NoLicense() {
  return (
    <div className='no-license'>
      No license information found.
      <br />
      Please contact the{' '}
      <a href='mailto:support@tridium.com' className='license-link'>
        Tridium Sales Team
      </a>{' '}
      to purchase a license
    </div>
  );
}
