/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import { RoleAssignmentWithRoleNumber } from '../../api/dto';
import { NiagaraRoleAssignmentPatch } from '../../api/management';
import { startsWithAuthPath } from './startsWithAuthPath';

export function generateCreateUpdateDeleteV2(
  originalRoleAssignments: Pick<RoleAssignmentWithRoleNumber, 'id' | 'scope' | 'roleNumber'>[],
  newRoleAssignments: Pick<RoleAssignmentWithRoleNumber, 'scope' | 'roleNumber'>[]
): NiagaraRoleAssignmentPatch {
  const cleanedNewRoleAssignments = newRoleAssignments.filter(
    x => !newRoleAssignments.some(y => x !== y && x.roleNumber === y.roleNumber && startsWithAuthPath(y.scope, x.scope))
  );

  const roleAssignmentsNotBeingChanged = cleanedNewRoleAssignments.filter(
    ({ scope: newScope, roleNumber: newRoleNumber }) =>
      originalRoleAssignments.some(
        ({ scope: oldScope, roleNumber: oldRoleNumber }) => newRoleNumber === oldRoleNumber && newScope === oldScope
      )
  );
  const newRoleAssignmentsBeingUpdatedOrCreated = cleanedNewRoleAssignments.filter(
    ({ scope: newScope, roleNumber: newRoleNumber }) =>
      !roleAssignmentsNotBeingChanged.some(
        ({ scope: unchangedScope, roleNumber: unchangedRoleNumber }) =>
          newRoleNumber === unchangedRoleNumber && newScope === unchangedScope
      )
  );
  const originalRoleAssignmentsBeingUpdatedOrDeleted = originalRoleAssignments.filter(
    ({ scope: oldScope, roleNumber: oldRoleNumber }) =>
      !roleAssignmentsNotBeingChanged.some(
        ({ scope: unchangedScope, roleNumber: unchangedRoleNumber }) =>
          oldRoleNumber === unchangedRoleNumber && oldScope === unchangedScope
      )
  );

  const idsToReuseOrDelete = originalRoleAssignmentsBeingUpdatedOrDeleted.map(({ id }) => id);

  const usedIdCount = Math.min(idsToReuseOrDelete.length, newRoleAssignmentsBeingUpdatedOrCreated.length);
  const relativeLength = idsToReuseOrDelete.length - newRoleAssignmentsBeingUpdatedOrCreated.length;
  // Positive: Unused IDs need to be deleted
  // Zero: All IDs will be reused
  // Negative: Additional assignments need to be created

  const deletedRoleAssignments =
    relativeLength > 0 ? idsToReuseOrDelete.slice(newRoleAssignmentsBeingUpdatedOrCreated.length) : [];
  const updatedRoleAssignments = idsToReuseOrDelete.slice(0, usedIdCount).map((id, i) => {
    const { scope, roleNumber } = newRoleAssignmentsBeingUpdatedOrCreated[i];
    return { role: `/roles/${roleNumber}`, scope, id };
  });
  const createdRoleAssignments =
    relativeLength < 0
      ? newRoleAssignmentsBeingUpdatedOrCreated
          .slice(relativeLength)
          .map(({ scope, roleNumber }) => ({ role: `/roles/${roleNumber}`, scope }))
      : [];

  return {
    create: createdRoleAssignments,
    update: updatedRoleAssignments,
    delete: deletedRoleAssignments,
  };
}

export function generateCreateUpdateDelete(
  originalRoleAssignments: Pick<RoleAssignmentWithRoleNumber, 'id' | 'scope' | 'roleNumber'>[],
  newRoleAssignments: Pick<RoleAssignmentWithRoleNumber, 'scope' | 'roleNumber'>[]
): NiagaraRoleAssignmentPatch {
  const cleanedNewRoleAssignments = newRoleAssignments.filter(
    x => !newRoleAssignments.some(y => x !== y && x.roleNumber === y.roleNumber && startsWithAuthPath(y.scope, x.scope))
  );

  const unchangedRoleAssignments = cleanedNewRoleAssignments.filter(x =>
    originalRoleAssignments.some(y => x.roleNumber === y.roleNumber && x.scope === y.scope)
  );
  const changedNewRoleAssignments = cleanedNewRoleAssignments.filter(
    x => !unchangedRoleAssignments.some(y => x.roleNumber === y.roleNumber && x.scope === y.scope)
  );
  const changedOriginalRoleAssignments = originalRoleAssignments.filter(
    x => !unchangedRoleAssignments.some(y => x.roleNumber === y.roleNumber && x.scope === y.scope)
  );

  let deletedRoleAssignments = changedOriginalRoleAssignments
    .filter(userRole => !changedNewRoleAssignments.some(localRole => localRole.scope === userRole.scope))
    .map(role => role.id);

  const allNewRoleAssignments = changedNewRoleAssignments.map(role => ({
    role: `/roles/${role.roleNumber}`,
    scope: role.scope,
  }));

  const updatedRoleAssignments = changedOriginalRoleAssignments.reduce<{ id: number; role: string; scope: string }[]>(
    function (result, localRole) {
      const updatedRole = allNewRoleAssignments.find(x => x.scope === localRole.scope);
      return updatedRole
        ? result.concat({
            ...updatedRole,
            id: changedOriginalRoleAssignments.find(x => x.scope === localRole.scope)!.id,
          })
        : result;
    },
    []
  );

  //if there exists any role in the updatedRoleAssignments with same scope but different id, then delete that role
  //eg: if the Role assigned is id: 1 role: 3 and id: 2 role: 6 and newRole is role: 1 for same scope,
  //then updatedRole will be id:1 role: 3 and delete id: 2 role: 6
  deletedRoleAssignments = deletedRoleAssignments.concat(
    changedOriginalRoleAssignments
      .filter(x => updatedRoleAssignments.some(y => x.id !== y.id && x.scope === y.scope))
      .map(role => role.id)
  );

  const createdRoleAssignments = allNewRoleAssignments.filter(
    x => !updatedRoleAssignments.some(y => x.role === y.role && x.scope === y.scope)
  );

  return {
    create: createdRoleAssignments,
    update: updatedRoleAssignments,
    delete: deletedRoleAssignments,
  };
}
