/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import React, { useMemo, useState } from 'react';
import {
  NiagaraCard,
  NiagaraSearch,
  NiagaraSidebar,
} from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';
import { Divider } from '@scuf/common';
import { useProjectStore } from './UseProjectStore';
import { CreateNewProjectButton } from './CreateNewProject';
import { toCanonical } from '../utils/toCanonical';
import Status from '../utils/Status';
import { DeleteProjectModal, SidebarEntry } from './ProjectListSidebarEntry';
import { showSnackBarProp } from '../components/TypeDefinitions';
import { Project } from '../api/management';
import { usePermissions } from '../userroles/usePermissions';
import { useCustomerStore } from '../customer';
import { useHistoryState } from '../utils/useHistoryState';
import { ProjectWithDevices } from './ProjectStore';

const ALL_PROJECTS_UNIQUE_ID = '__0-all-projects'; // leading '__0-' is important to keep all projects option at the top

export default function ProjectList({ showSnackBar }: { showSnackBar: showSnackBarProp }) {
  const { currentCustomer } = useCustomerStore();
  const { projectsStatus, projectsError, projects, currentProject, setCurrentProject } = useProjectStore();
  const [projectToDelete, setProjectToDelete] = useState<Project>();
  const { Management } = usePermissions();
  const [search, setSearch] = useState<string>();
  const [settings, setSettings] = useHistoryState<{ projectId?: string; page?: number }>();

  const isDeletePermitted = projectToDelete && Management.Projects.DeleteProject(projectToDelete);
  const isCreatePermitted = currentCustomer && Management.Projects.CreateProject(currentCustomer);
  const filteredProjects = useMemo(() => {
    const searchCanonical = toCanonical(search);
    return projects?.filter(
      ({ projectName }) => !searchCanonical || toCanonical(projectName)?.includes(searchCanonical)
    );
  }, [projects, search]);

  const totalCountOfDevices = calculateTotalDeviceCount(filteredProjects);

  const showAllProjectsOption = !search && filteredProjects?.length > 1;
  const selectedProjectUniqueId = currentProject?.id.toString() ?? settings?.projectId;
  return (
    <NiagaraCard className='project-list'>
      <div className='project-list-header'>
        <div className='card-title'>Projects ({filteredProjects?.length ?? 0})</div>
      </div>
      <Divider fitted={true} />
      <div className='project-list-body left-data-container'>
        <NiagaraSearch placeholder='Search Project' value={search} results={[]} onSearchChange={setSearch} />
        <Status status={projectsStatus} error={projectsError}>
          <NiagaraSidebar
            className='sidebar-project'
            selected={selectedProjectUniqueId && `_${selectedProjectUniqueId}`}
            onSelect={key => {
              const selectedProject = projects.find(p => `_${p.id}` === key);
              setSettings({ projectId: selectedProject?.id.toString(), page: 0 });
              setCurrentProject(selectedProject);
            }}
            items={{
              ...(showAllProjectsOption
                ? {
                    [ALL_PROJECTS_UNIQUE_ID]: <div className='all-projects-selection'><div>All Projects</div>{totalCountOfDevices}</div>,
                  }
                : {}),
              ...filteredProjects?.reduce(
                (out, p) => ({
                  ...out,
                  [`_${p.id}`]: <SidebarEntry {...p} onDeleteClick={setProjectToDelete} showSnackBar={showSnackBar} />,
                }),
                {}
              ),
            }}
          />
          {projectToDelete && isDeletePermitted && (
            <DeleteProjectModal
              projectToDelete={projectToDelete}
              onClose={() => {
                setProjectToDelete(undefined);
              }}
            />
          )}
        </Status>
      </div>
      {isCreatePermitted && (
        <>
          <Divider fitted={true} />
          <div className='project-list-footer'>
            <CreateNewProjectButton />
          </div>
        </>
      )}
    </NiagaraCard>
  );
}
export function calculateTotalDeviceCount(projects: ProjectWithDevices[]){
  return projects?.reduce((total, project) => total + project.devices.length, 0) ?? 0;
}
