/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import { IRoleAssignmentMin, accessRoleFromRoleNumber } from './roleAssignment';
import { NOT_ASSIGNED } from './typesAndConstants';

export function internal(roleAssignments: IRoleAssignmentMin[], authorizationPaths: string[]) {
  const role = roleAssignments.filter(({ scope }) => authorizationPaths.some(p => scope.match(RegExp('^' + p + '(/.*)+$', 'i')))
  );
  return Array.from(new Set(role.map(accessRoleFromRoleNumber))).filter(x => x !== NOT_ASSIGNED);
}
