/*
* Copyright 2023 Tridium Inc. All rights reserved.
*/
import { bytesToString } from '../metricsUtils';
import { Point } from 'highcharts';
import './tooltip.scss';

interface ICustomChartPoint extends Point {
  unit?: string;
  isNumeric: boolean;
  status: string;
}

export function areaTooltip(): Highcharts.TooltipOptions {
  return {
    enabled: true,
    borderColor: 'transparent',
    formatter(this) {
      return bytesToString(this.y, true, 2);
    },
  };
}

export function lineTooltip(): Highcharts.TooltipOptions {
  return {
    borderColor: '#FFFFFF',
    backgroundColor: '#FFFFFF',
    shared: true,
    borderRadius: 5,
    useHTML: true,
    formatter: function (this) {
      var tooltip = '';
      this.points?.forEach(function (point) {
        tooltip += `<div class="tooltip-container"><div class="legend-container"><div class="legend-tooltip" style="background:${point.color
          };"></div> ${point.series.name} </div><div>${bytesToString(point.y, true, 2)}</div></div>`;
      });
      return tooltip;
    },
  };
}

export function stackedColumnTooltip(): Highcharts.TooltipOptions {
  return {
    enabled: false,
  };
}
