/*
 * Copyright 2024 Tridium Inc. All rights reserved.
 */
import React, { useEffect, useState } from 'react';
import { Progress } from '@scuf/common';
import './RetrievalMetricsBar.scss';
import { NiagaraIcon, NiagaraLabel } from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';

interface IRetrievalMetricsBar {
  usage?: number;
  unitStr?: string;
  usageLimit?: number;
  usageLimitStr?: string;
  colors?: string[];
}
const totalCapacity = 150;
const baselineArrow = 66.5;

export function RetrievalMetricsBar({
  usage = 0,
  unitStr = '',
  usageLimit = 0,
  usageLimitStr = '',
  colors = ['#159D62', '#D1670B', '#EE3124', '#F4F4F4'],
}: IRetrievalMetricsBar) {
  const limitExceeded = (usage - usageLimit).toFixed(0);
  const totalPercentage = (usage / usageLimit) * 100;
  const percentageGreen = Math.min(!Number.isNaN(totalPercentage) ? totalPercentage : 0, 80);
  const percentageOrange = totalPercentage > 80 ? Math.min(totalPercentage - 80, 20) : 0;
  const percentageRed = totalPercentage > 100 ? Math.min(totalPercentage - 100, 50) : 0;
  let usageLineArrow =
    totalPercentage > 104 ? baselineArrow + ((totalPercentage - 100) / 50) * (100 - baselineArrow) - 4 : 65.66;
  if (totalPercentage >= 150) {
    usageLineArrow = 95.633;
  }
  return (
    <div className='retrieval-usage'>
      <div>
        <NiagaraLabel label='Retrieval Usage' />
      </div>
      <div className='progress-container'>
        <Progress
          size='xxsmall'
          total={totalCapacity}
          stackedValues={[percentageGreen, percentageOrange, percentageRed]}
          colors={colors}
        />
        <div className='baseline-line' style={{ left: `${baselineArrow}%` }}></div>
        <div className='baseline-text'>
          <div className='baseline-value'>{unitStr}</div>
          <div className='baseline-info'>used / {usageLimitStr}</div>
        </div>
        {percentageRed > 0 && (
          <div className='usage-line' style={{ left: `${usageLineArrow}%` }}>
            <div className='indicator'>
              <NiagaraIcon size={12} color='#EE3124' name='Indicator' />
            </div>
            <div className='info-text' style={{ margin: totalPercentage >= 150 ? '-8px -8px -8px -38px' : '-8px' }}>
              {limitExceeded} GB Over
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
