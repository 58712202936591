/*
 * Copyright 2024 Tridium Inc. All rights reserved.
 */

import React from 'react';
import { NiagaraTooltip } from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';
import './TableColumnTooltip.scss';
import { useIsRefTruncated } from '../utils/useIsRefTruncated';

interface TableColumnTooltipProps {
  columnData: string;
  position?:
    | 'top left'
    | 'top right'
    | 'bottom right'
    | 'bottom left'
    | 'right center'
    | 'left center'
    | 'top center'
    | 'bottom center';
  flowing?: boolean;
}

export const TableColumnTooltip: React.FC<TableColumnTooltipProps> = ({
  columnData,
  position = 'bottom center',
  flowing = false,
}) => {
  const { ref, isTruncatedHorizontally } = useIsRefTruncated<HTMLDivElement>();
  return (
    <div className='column-container'>
      {isTruncatedHorizontally ? (
        <NiagaraTooltip
          className='asset-tooltip'
          data-testid='tooltip-element'
          content={columnData}
          element={
            <div ref={ref} className='column-tooltip'>
              {columnData}
            </div>
          }
          position={position}
          event='hover'
          flowing={flowing}
        />
      ) : (
        <div ref={ref} className='column-tooltip'>
          {columnData}
        </div>
      )}
    </div>
  );
};
