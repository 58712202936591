/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import React, { PropsWithChildren } from 'react';
import './MetricsInfo.scss';

interface IMetricsInfo {
  value?: string;
  context?: string;
}

export function MetricsInfo({ value = '-', context, children }: PropsWithChildren<IMetricsInfo>) {
  return (
    <div className='metrics-info'>
      <span className='value'>{value}</span>
      {value !== '-' && context && <span className='context'>{context}</span>}
      {children}
    </div>
  );
}
