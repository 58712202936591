/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import {
  IRoleAssignmentMin,
  accessRoleFromRoleNumber,
  isHigherPriority,
  isDifferentRoleAndEqualPriority,
} from './roleAssignment';
import { NOT_ASSIGNED } from './typesAndConstants';

export function inherited(roleAssignments: IRoleAssignmentMin[], authorizationPaths: string[]) {
  const matches = roleAssignments.filter(({ scope }) =>
    authorizationPaths.some(p => p.match(RegExp('^' + scope + '(/.*)*$', 'i')))
  );
  const roles = matches.map(r => accessRoleFromRoleNumber(r.roleNumber)).filter(x => x !== NOT_ASSIGNED);
  return roles.reduce((current, role) => {
    if (current.length === 0 || isHigherPriority(role, current[0])) return [role];
    if (isDifferentRoleAndEqualPriority(role, current[0])) return [...current, role];
    return current;
  }, [] as typeof roles);
}
