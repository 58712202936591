/*
 * Copyright 2022 Tridium Inc. All rights reserved.
 */
export const MAX_ALLOWED_POINT_SELECTION = 10;
export const SEARCH_DEBOUNCE_TIMER = 300;
export const LONG_SEARCH_DEBOUNCE_TIMEOUT = 1000;
export const MAX_PROJECTNAME_LENGTH = 50;
export const MAX_DEVICE_NAME_LENGTH = 50;
export const MAX_DEVICE_LOCATION_LENGTH = 50;
export const PAGINATION_DEBOUNCE_TIMER = 300;

export const DEFAULT_ERROR_TITLE = 'Something went wrong';
export const DEFAULT_ERROR_TEXT =
  "Sorry, there's a problem connecting to the data service right now, Please try again.";
export const LICENSE_URL = window.ENV.LICENSE_URL;
export const POINT_ICON_BLUE = '#5B4BB3';
export const POINT_ICON_GREEN = '#008813';
export const POINT_ICON_GREY = '#707070';
export const POINT_ICON_ORANGE = '#D1670B';
export const exportCompleteMessage = 'Successful export to browser Downloads';
