/*
 * Copyright 2024 Tridium Inc. All rights reserved.
 */
const TEXT_AREA_REGEX = /^[a-zA-Z0-9\-_&,.'` \n]*$/;
export const VALID_CHARACTERS_FOR_LABELS = /^[a-zA-Z0-9\-_&,. '`]*$/;
export function ValidateText(value: string) {
   return value.length > 0 && !Boolean(TEXT_AREA_REGEX.exec(value))
      ? `Symbols like *,<,>,/ are not allowed`
      : undefined;
}
