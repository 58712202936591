/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import { niagaraHttp } from '../../utils/niagaraHttp';

export type ISubscription = {
  offeringCode: string;
  startDate: string;
  endDate: string;
};

export type ISubscriptionDTO = {
  deviceUuid: string;
  subscriptions: ISubscription[];
};

const SUBSCRIPTION_API_BASE_URL = `${window.ENV.API_BASE_URL}/api/v1/subscription`;

export async function getSubscriptions(customerId: number, abortSignal: AbortSignal) {
  return niagaraHttp.get<ISubscriptionDTO[]>(`${SUBSCRIPTION_API_BASE_URL}/customers/${customerId}`, {
    signal: abortSignal,
  });
}
