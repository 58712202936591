/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import { getAuthorization } from '../utils/roleAssignment/roleAssignment';
import { convertToRoleAssignment } from '../api/dto';
import { useAuthenticatedUser } from '../utils/Authentication';

export function useUserRoleStore() {
  const { currentUser, roles } = useAuthenticatedUser();
  const roleAssignments = currentUser?.roleAssignments?.map(convertToRoleAssignment) ?? [];
  return {
    currentUser,
    roles,
    get hasPartnerAdminRole() {
      return roleAssignments.some(r => /partner_admin/.test(r.roleName ?? ''));
    },
    get hasPartnerUserRole() {
      return roleAssignments.some(r => /partner_user/.test(r.roleName ?? ''));
    },
    get hasCustomerAdminRole() {
      return roleAssignments.some(r => /customer_admin/.test(r.roleName ?? ''));
    },
    get hasCustomerUserRole() {
      return roleAssignments.some(r => /customer_user/.test(r.roleName ?? ''));
    },
    get hasGlobalAdminRole() {
      return roleAssignments.some(r => /global_admin/.test(r.roleName ?? ''));
    },
    get hasNiagaraRemoteRole() {
      return roleAssignments.some(r => /niagara_remote/.test(r.roleName ?? ''));
    },
    get hasNDSOperatorRole() {
      return roleAssignments.some(r => /nds_operator/.test(r.roleName ?? ''));
    },
    getScopedAccess(...scopes: string[]) {
      return getAuthorization(roleAssignments, scopes).overall;
    },
    get isUserTypePartner() {
      return currentUser?.userType === 'PARTNER';
    },
    get isUserTypeCustomer() {
      return currentUser?.userType === 'CUSTOMER';
    },
    get isUserTypeGlobalAdmin() {
      return currentUser?.userType === 'INTERNAL';
    },
    get isUserTypeUnassigned() {
      return currentUser?.userType === 'UNASSIGNED';
    },
  };
}
