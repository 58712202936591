/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import { RoleAssignmentWithOptionalId } from '../../api/dto';
import {
  isDifferentRoleAndHigherOrSamePriority,
  getRoleOptionFromRoleNumber,
  isDifferentRoleAndEqualPriority,
} from '../../utils/roleAssignment/roleAssignment';
import { IAccessRole } from '../../utils/roleAssignment/typesAndConstants';
import { startsWithAuthPath } from './startsWithAuthPath';

export function setAssignment(
  originalRoleAssignments: Pick<RoleAssignmentWithOptionalId, 'scope' | 'roleNumber' | 'roleName'>[],
  authPath: string | undefined,
  updatedRole: IAccessRole,
  forceOverride?: boolean,
  roleChange?: boolean
) {
  if (!authPath) return originalRoleAssignments;
  const scopeWithDifferentRoleOfEqualPriorityExists = originalRoleAssignments.some(
    ({ scope, roleNumber }) =>
      authPath === scope && isDifferentRoleAndEqualPriority(getRoleOptionFromRoleNumber(roleNumber), updatedRole)
  );
  return originalRoleAssignments
    .filter(({ scope, roleNumber }) =>
      roleChange
        ? !startsWithAuthPath(authPath, scope) || authPath !== scope
        : !startsWithAuthPath(authPath, scope) ||
          (authPath === scope && scopeWithDifferentRoleOfEqualPriorityExists) ||
          ((!forceOverride || scopeWithDifferentRoleOfEqualPriorityExists) &&
            startsWithAuthPath(`${authPath}(/.+)+`, scope) &&
            isDifferentRoleAndHigherOrSamePriority(getRoleOptionFromRoleNumber(roleNumber), updatedRole))
    )
    .concat([{ scope: authPath, roleNumber: updatedRole.roleNumber }]);
}

export function changeAssignments(
  originalRoleAssignments: RoleAssignmentWithOptionalId[],
  authPath: string | undefined,
  oldRole: IAccessRole,
  newRole: IAccessRole
) {
  const isServiceAccount = !authPath?.includes('customers');
  const assignmentsToChange = authPath
    ? originalRoleAssignments.filter(x => matchesAuthPathAndRoleNumber(x, authPath, oldRole))
    : [];
  return assignmentsToChange.reduce(
    (assignments, { scope }) => setAssignment(assignments, scope, newRole, false, !isServiceAccount),
    originalRoleAssignments
  );
}

export function removeAssignments(
  originalRoleAssignments: RoleAssignmentWithOptionalId[],
  authPath: string | undefined,
  roleToRemove: IAccessRole
) {
  return authPath
    ? originalRoleAssignments.filter(x => !matchesAuthPathAndRoleNumber(x, authPath, roleToRemove))
    : originalRoleAssignments;
}

function matchesAuthPathAndRoleNumber(
  { scope, roleNumber }: Pick<RoleAssignmentWithOptionalId, 'scope' | 'roleNumber'>,
  authPath: string,
  roleToRemove: IAccessRole
) {
  return startsWithAuthPath(authPath, scope) && roleNumber === roleToRemove.roleNumber;
}
