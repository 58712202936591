/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { Loader } from '../Loader';
import { IRole, NiagaraMyUser, getMyUserAsync, getRolesAsync } from '../../api/management';
import { ApiError } from '../niagaraHttp';
import { ErrorPage } from '../../components/errorpage';
import assert from 'assert';

// TODO - default is not tested
export default function DefaultProtectedContent({ children }: { children: ReactNode }) {
  return ProtectedContent({ ...useProtectedContent(getMyUserAndRolesAsync), children });
}

export async function getMyUserAndRolesAsync() {
  const [currentUser, roles] = await Promise.all([getMyUserAsync(), getRolesAsync()]);
  return { currentUser, roles };
}

export function useProtectedContent(
  getMyUserAndRolesAsync: () => Promise<{ currentUser: NiagaraMyUser; roles: IRole[] }>
) {
  const [state, update] = useState<{ currentUser?: NiagaraMyUser; roles?: IRole[]; error?: ApiError }>({});
  useEffect(() => {
    let _update = update;
    getMyUserAndRolesAsync()
      .then(_state => _update(_state))
      .catch(error => _update({ error }));
    return () => void (_update = () => {});
  }, []);
  return state;
}

const ctx = React.createContext<{
  currentUser: NiagaraMyUser;
  roles: IRole[];
}>(undefined!);

export function ProtectedContent({
  error,
  currentUser,
  roles,
  children,
}: ReturnType<typeof useProtectedContent> & { children?: ReactNode }) {
  return error ? (
    <ErrorPage {...error} />
  ) : !currentUser || !roles ? (
    <Loader className='main-loader' text='Please wait...' />
  ) : (
    <ctx.Provider value={{ currentUser, roles }}>{children}</ctx.Provider>
  );
}

export function useAuthenticatedUser() {
  const c = useContext(ctx);
  assert(c, `${useAuthenticatedUser.name} must be used within the scope of ${ProtectedContent.name}`);
  return c;
}

// TODO - replace all usage of hook above with one of these...
export function useCurrentUser() {
  return useAuthenticatedUser().currentUser;
}
export function useRoles() {
  return useAuthenticatedUser().roles;
}
