/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import { NiagaraRoleAssignment } from '../management';
export type { ILicense } from './ILicense';
export type { ILicensesDTO } from './ILicensesDTO';
export type { NiagaraDeviceRegistration } from './NiagaraDeviceRegistration';
export type { IProject } from './IProject';
export type { IProjectsDTO } from './IProjectsDTO';

export type RoleAssignmentWithOptionalId = Omit<RoleAssignmentWithRoleNumber, 'id'> &
  Partial<Pick<RoleAssignmentWithRoleNumber, 'id'>>;

export interface RoleAssignmentWithRoleNumber extends Omit<NiagaraRoleAssignment, '_links' | 'roleName'> {
  roleNumber: string | undefined;
  roleName: string | undefined;
}

export function convertToRoleAssignment(roleAssignment: NiagaraRoleAssignment): RoleAssignmentWithRoleNumber {
  const { _links, ...ra } = roleAssignment;
  return { ...ra, roleNumber: _links?.role?.href?.split('/').pop() };
}
