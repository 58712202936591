/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import React from 'react';
import {
  NiagaraTooltip,
  ProgressBar,
} from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';

interface IMetricsBar {
  percentage: number;
  color: string;
  limitColor?: string;
  tooltip?: string;
}

export function MetricsBar({ percentage, color, limitColor = '#ee3124', tooltip }: IMetricsBar) {
  const progressBarColor = percentage < 90 ? color : limitColor;
  const progressBar = <ProgressBar percentage={percentage} color={progressBarColor} />;

  return tooltip ? (
    <NiagaraTooltip content={tooltip} element={progressBar} position='bottom center' event='hover' />
  ) : (
    progressBar
  );
}
