/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import React, { ReactNode } from 'react';
import {
  showToast as showNiagaraToast,
  NiagaraToast,
  NiagaraButton2,
} from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';
import './Toast.scss';

export interface IToastInterface {
  className?: string;
  badgeLabel: Parameters<typeof NiagaraToast>[0]['badge']['label'];
  badgeIcon: Parameters<typeof NiagaraToast>[0]['badge']['icon'];
  header: string;
  subHeader?: string;
  description?: ReactNode;
}

export type IActionButtonPosition = 'right' | 'left';
export interface IToastActions {
  primaryText?: string;
  primaryAction?: () => void;
  primaryButtonPosition?: IActionButtonPosition;
  secondaryText?: string;
  secondaryAction?: () => void;
  secondaryButtonPosition?: IActionButtonPosition;
}
export function showToast({
  className,
  badgeLabel,
  badgeIcon,
  header,
  subHeader,
  description,
}: IToastInterface) {
  showNiagaraToast(
    <NiagaraToast showClose={false} badge={{ label: badgeLabel, icon: badgeIcon }}>
      <div className={`app-toast ${className ?? ''}`}>
        <div className='toast-header'>{header}</div>
        {subHeader && <div className='toast-sub-header'>{subHeader}</div>}
        {description && <div className='toast-description'>{description}</div>}
      </div>
    </NiagaraToast>
  );
}

export function showSuccessToast(header: string, description?: ReactNode, subHeader?: string) {
  showToast({ badgeLabel: 'success', badgeIcon: 'success', header, subHeader, description });
}

export function showErrorToast(header: string, description?: ReactNode) {
  showToast({ badgeLabel: 'error', badgeIcon: 'error', header, description });
}

export function ToastActions({
  primaryText,
  primaryAction,
  primaryButtonPosition,
  secondaryText,
  secondaryAction,
  secondaryButtonPosition,
}: IToastActions) {
  return (
    <div className='toast-actions'>
      {secondaryText && (
        <NiagaraButton2
          className={`toast-action-button secondary-button ${secondaryButtonPosition}`}
          type='secondary-link'
          content={secondaryText}
          onClick={secondaryAction}
        />
      )}
      {primaryText && (
        <NiagaraButton2
          className={`toast-action-button primary-button ${primaryButtonPosition}`}
          type='primary-link'
          content={primaryText}
          onClick={primaryAction}
        />
      )}
    </div>
  );
}
