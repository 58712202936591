/*
 * Copyright 2024 Tridium Inc. All rights reserved.
 */
import React, { ReactNode, useContext, useEffect, useState, createContext } from 'react';
import assert from 'assert';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getValueFromLocalStorage, saveValueToLocalStorage } from '../localStorage';

const ThemeContext = createContext<{ theme: string; toggleTheme: () => void }>(undefined!);

export const ThemeProvider = ({ children }: { children?: ReactNode }) => {
  const { enableDarkTheme } = useFlags();
  const [theme, setTheme] = useState('light');
  useEffect(() => {
    if (enableDarkTheme) {
      const storedTheme = getValueFromLocalStorage('theme');
      !!storedTheme && setTheme(storedTheme);
      !!storedTheme && document.documentElement.setAttribute('data-theme', storedTheme);
    } else {
      localStorage.removeItem('theme');
      document.documentElement.removeAttribute('data-theme');
    }
  }, [enableDarkTheme]);

  const toggleTheme = () => {
    const toggledTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(toggledTheme);
    document.documentElement.setAttribute('data-theme', toggledTheme);
    saveValueToLocalStorage('theme', toggledTheme);
  };

  return <ThemeContext.Provider value={{ theme, toggleTheme }}>{children}</ThemeContext.Provider>;
};

export function useTheme() {
  const c = useContext(ThemeContext);
  assert(c, `${useTheme.name} must be used within the scope of ${useTheme.name}`);
  return c;
}
