/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
export function getStringFromLocalStorage(userId: number, key: string) {
  return window.localStorage.getItem(`${userId}.${key}`);
}

export function getObjectFromLocalStorage(userId: number, key: string) {
  const storageValue = getStringFromLocalStorage(userId, key);
  if (storageValue === null) {
    return null;
  }
  try {
    return JSON.parse(storageValue);
  } catch {
    return null;
  }
}

export function saveStringToLocalStorage(userId: number, key: string, string: string) {
  window.localStorage.setItem(`${userId}.${key}`, string);
}

export function saveObjectToLocalStorage(userId: number, key: string, object: object) {
  saveStringToLocalStorage(userId, key, JSON.stringify(object));
}

export function saveValueToLocalStorage(key: string, string: string) {
  window.localStorage.setItem(key, string);
}

export function getValueFromLocalStorage(key: string) {
  return window.localStorage.getItem(key);
}
