/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */

/** Similar to inbuilt URLSearchParams but:
 * - does not urlencode 'sort' (useful for badly designed apis which expect comma separated param lists, eg. 'sort,[asc|desc]')
 * - includes the prefix '?'
 * */
export function toSearchParams<T = any, K = keyof T>(
  params?: Record<string, string | number | boolean | undefined> | { sort?: K | K[] }
) {
  if (!params || Object.keys(params).length === 0) {
    return '';
  }
  const { sort, ..._params } = removeUndefined({
    ...params,
    sort: Array.isArray(params.sort) ? params.sort?.join(',') : params.sort,
  });
  const searchParams = `?${new URLSearchParams(sort ? { ..._params, sort } : _params)}`.replace(
    encodeURIComponent(sort ?? ''),
    sort ?? ''
  );
  return searchParams;
}

export function removeUndefined<T extends object>(t: T) {
  return Object.fromEntries(Object.entries(t).filter(([_, v]) => v !== undefined));
}
