/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import { SearchRequest } from '../../utils/Query';
import { niagaraHttp } from '../../utils/niagaraHttp';
import { Paged } from '../Paged';
export type SourceState = 'normal' | 'offnormal' | 'fault' | 'alert' | 'highlimit' | 'lowlimit';
export type AckState = 'unacked' | 'acked' | 'ackpending';
export type Alarm = {
  deviceUuid: string;
  alarmUuid: string;
  iotMessageId: string;
  cloudId: string;
  generatedTime: string;
  sourceState: SourceState;
  ackState: AckState;
  ackRequired: boolean;
  source: string;
  alarmClass: string;
  priority: number;
  normalTime: string;
  ackTime: string;
  userName: string;
  alarmTransition: 'OFFNORMAL' | 'NORMAL';
  lastUpdateTime: string;
  alarmData: Record<string, string | number | boolean>;
  deviceName?: string;
  projectName?: string;
  msgText?: String;
};

export type Page = { size: number; number: number };

type AlarmsResponse = Paged<{ alarms: Alarm[] }>;

type AcknowledgeAlarmDetails = {
  alarmUuid: string;
  message: string;
  successful: boolean;
};
type EditAlarmDataDetails = {
  alarmUuid: string;
  message: string;
  notes: {
    message: string;
    successful: boolean;
  };
};
export type EditAlarmDataResponse = {
  editAlarmDetails: EditAlarmDataDetails[];
};
export type AcknowledgeAlarmResponse = {
  acknowledgeAlarmDetails: AcknowledgeAlarmDetails[];
};

export type AlarmsCountResponse = {
  deviceUuid: string;
  cloudId: string;
  iotMessageId: string;
  acked: number;
  unacked: number;
  ackPending: number;
  total: number;
  lastUpdateTime: 'string';
};
const REQUEST_PROCESSING_PRIORITY = 255;

export async function fetchAlarmsAsync(
  customerId: number,
  body: SearchRequest<Alarm> = {},
  config?: { signal?: AbortSignal }
) {
  return niagaraHttp.post<AlarmsResponse>(
    `${window.ENV.API_BASE_URL}/api/v1/alarms/customers/${customerId}/alarms:search`,
    body,
    config
  );
}

export async function acknowledgeAlarmAsync(systemGuid: string, alarmUuids: string[]) {
  return niagaraHttp.post<AcknowledgeAlarmResponse>(
    `${window.ENV.API_BASE_URL}/api/v1/control/devices/${systemGuid}/commands/acknowledgeAlarm`,
    { alarmUuids, requestProcessingPriority: REQUEST_PROCESSING_PRIORITY }
  );
}

export async function acknowledgeAlarmBatchAsync(payload: { alarmUuids: string[]; systemGuid: string }[]) {
  const requestPayload = payload.map((p) => ({
    ...p,
    requestProcessingPriority: REQUEST_PROCESSING_PRIORITY,
  }));
  return niagaraHttp.post<AcknowledgeAlarmResponse[]>(
    `${window.ENV.API_BASE_URL}/api/v1/control/devices/commands/acknowledgeAlarmBatch`,
    requestPayload
  );
}
export function editNotesForAlarms(
  systemGuid: string,
  alarms: {
    alarmUuid: string;
    notes: string;
  }[]
) {
  return niagaraHttp.post<EditAlarmDataResponse>(
    `${window.ENV.API_BASE_URL}/api/v1/control/devices/${systemGuid}/commands/editAlarmData`,
    { alarms, requestProcessingPriority: REQUEST_PROCESSING_PRIORITY }
  );
}

export async function getAlarmCountAsync(customerId: string, deviceUuid: string) {
  return await niagaraHttp.get<AlarmsCountResponse>(
    `${window.ENV.API_BASE_URL}/api/v1/alarms/customers/${customerId}/devices/${deviceUuid}/alarms/count`
  );
}

const sampleQuery0 = (deviceId: number) => ({
  query: {
    op: 'and',
    expr: [
      { field: 'deviceUuid', op: 'eq', value: deviceId },
      { field: 'alarmData.buildingNum', op: 'eq', value: 122 },
    ],
  },
  page: { size: 100, number: 0 },
  sort: { order: [{ by: 'lastUpdateTime', direction: 'desc' }] },
});

const sampleQuery1: SearchRequest<Alarm> = {
  query: {
    op: 'and',
    expr: [
      {
        field: 'deviceUuid',
        op: 'eq',
        value: '6f42bcea-5d96-4164-bb17-fd4cbbb9cc90',
      },
      { field: 'sourceState', op: 'eq', value: 'OFFNORMAL' },
      { field: 'ackRequired', op: 'eq', value: true },
    ],
  },
  page: { size: 100, number: 0 },
  sort: { order: [{ by: 'lastUpdateTime', direction: 'desc' }] },
};
