/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import React, { ReactNode } from 'react';
import { NiagaraButton2 } from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';
import { DEFAULT_ERROR_TEXT, DEFAULT_ERROR_TITLE } from '../../utils/Constants';
import './ErrorPage.scss';

export interface IErrorPageProps {
  title?: string;
  errorText?: string;
  onRefresh?: () => void;
  supportMessageRequired?: boolean;
  children?: ReactNode
}

export function ErrorPage({
  title = DEFAULT_ERROR_TITLE,
  errorText = DEFAULT_ERROR_TEXT,
  onRefresh,
  supportMessageRequired = true,
  children
}: IErrorPageProps) {
  return (
    <div className='error-body'>
      <div className='error-title'>{title}</div>
      <div className='error-text'>
        {errorText}
        {supportMessageRequired ? (
          <div className='support-message'>
            <br />
            If the problem continues, please contact your direct customer support or Tridium support at:
            <br />
            Toll-free: +1 877-305-1745
            <br />
            Email: <a href='mailto:support@tridium.com'>support@tridium.com</a>
          </div>
        ) : null}
      </div>
      {onRefresh ? <NiagaraButton2 type='secondary' content='Refresh' onClick={() => onRefresh()} /> : null}
      {children}
    </div>
  );
}
