/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import { UserManager } from 'oidc-client-ts';

const SESSION_EXPIRED_WARNING_TIME_SECONDS = asNumber(window.ENV.SESSION_EXPIRED_COUNT_DOWN_SECONDS) ?? 60;

function asNumber(s?: string) {
  const n = parseInt(s ?? '', 10);
  return isFinite(n) ? n : undefined;
}

const singleton = new UserManager({
  automaticSilentRenew: false,
  accessTokenExpiringNotificationTimeInSeconds: SESSION_EXPIRED_WARNING_TIME_SECONDS,
  authority: window.ENV.OIDC_AUTHORITY_URL,
  client_id: window.ENV.OIDC_CLIENT_ID,
  scope: 'openid profile email offline_access ncp:read ncp:write',
  redirect_uri: window.location.origin + '/callback',
  post_logout_redirect_uri: window.ENV.OIDC_LOGOUT_URL,
});
export default singleton;
