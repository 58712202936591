/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import React from 'react';

export function Logout() {
  const logoutUrls = window.ENV.SINGLE_LOGOUT_URL?.split(';');
  return (
    <div>
      <div>Logout</div>
      {logoutUrls?.map((url, i) => (
        <iframe key={i} title={url} src={url} hidden={true}></iframe>
      ))}
    </div>
  );
}
