/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
export type IRoleType = 'admin' | 'user' | 'niagara remote' | 'custom' | 'noaccess' |'nds operator';
export type IRoleName =
  | 'global_admin'
  | 'partner_admin'
  | 'partner_user'
  | 'customer_admin'
  | 'customer_user'
  | 'niagara_remote'
  | 'custom'
  | 'noaccess'
  | 'nds_operator';

export type IAccessRole = {
  roleName: IRoleName;
  roleType: IRoleType;
  roleNumber: string | undefined;
  text: string;
};
export const GLOBAL_ADMIN: IAccessRole = {
  roleName: 'global_admin',
  roleType: 'admin',
  roleNumber: '1',
  text: 'Global Admin',
};
export const PARTNER_ADMIN: IAccessRole = {
  roleName: 'partner_admin',
  roleType: 'admin',
  roleNumber: '2',
  text: 'Admin',
};
export const PARTNER_USER: IAccessRole = {
  roleName: 'partner_user',
  roleType: 'user',
  roleNumber: '3',
  text: 'User',
};
export const CUSTOMER_ADMIN: IAccessRole = {
  roleName: 'customer_admin',
  roleType: 'admin',
  roleNumber: '4',
  text: 'Admin',
};
export const CUSTOMER_USER: IAccessRole = {
  roleName: 'customer_user',
  roleType: 'user',
  roleNumber: '5',
  text: 'User',
};
export const NIAGARA_REMOTE: IAccessRole = {
  roleName: 'niagara_remote',
  roleType: 'niagara remote',
  roleNumber: '6',
  text: 'Niagara Remote',
};
export const CUSTOM: IAccessRole = {
  roleName: 'custom',
  roleType: 'custom',
  roleNumber: undefined,
  text: 'Custom',
};
export const NOT_ASSIGNED: IAccessRole = {
  roleName: 'noaccess',
  roleType: 'noaccess',
  roleNumber: undefined,
  text: 'No Access',
};
export const NDS_OPERATOR: IAccessRole = {
  roleName: 'nds_operator',
  roleType: 'nds operator',
  roleNumber: '7',
  text: 'NDS Operator',
};

// Ordered arrays
export const OverallPartnerOptions = [PARTNER_ADMIN, PARTNER_USER, NIAGARA_REMOTE, CUSTOM, NOT_ASSIGNED, NDS_OPERATOR];
const OverallCustomerOptions = [CUSTOM, NOT_ASSIGNED];
const OverallServiceAccountOptions = [CUSTOM];
const PartnerSectionOptions = [PARTNER_ADMIN, PARTNER_USER, NIAGARA_REMOTE, NDS_OPERATOR];
const CustomerSectionOptions = [CUSTOMER_ADMIN, CUSTOMER_USER, NIAGARA_REMOTE, NDS_OPERATOR];
const ServiceAccountSectionOptions = [CUSTOMER_USER, NDS_OPERATOR];

export const OverallOptions = new Map([
  ['PARTNER', OverallPartnerOptions],
  ['CUSTOMER', OverallCustomerOptions],
  ['SERVICE ACCOUNT', OverallServiceAccountOptions],
]);
export const SectionOptions = new Map([
  ['PARTNER', PartnerSectionOptions],
  ['CUSTOMER', CustomerSectionOptions],
  ['SERVICE ACCOUNT', ServiceAccountSectionOptions],
]);

export const PrioritizedRoleOptions = [
  [GLOBAL_ADMIN],
  [PARTNER_ADMIN, CUSTOMER_ADMIN],
  [PARTNER_USER, CUSTOMER_USER, NIAGARA_REMOTE, NDS_OPERATOR],
  [CUSTOM],
  [NOT_ASSIGNED],
];
export const AllRoleOptions = PrioritizedRoleOptions.flat();
export type IAssignableAccessRole =
  | typeof GLOBAL_ADMIN
  | typeof PARTNER_ADMIN
  | typeof PARTNER_USER
  | typeof CUSTOMER_ADMIN
  | typeof CUSTOMER_USER
  | typeof NIAGARA_REMOTE
  | typeof NDS_OPERATOR;

export const roleTypeOrdering = new Map(AllRoleOptions.map((v, i) => [v, i]));
