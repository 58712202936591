/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import { waitForever } from '../../waitForever';
import { getState, setState } from './PageStateFunctions';
import assertAuthenticated from './assertAuthenticated';
import { sendRemoteLogoutRequest } from './sendRemoteLogoutRequest';
import singleton from './userManager';
export * from './userManager';
export { useOidcUser } from './useOidcUser';

export const login = () => singleton.signinRedirect({ state: getState() }).then(waitForever);
export const signinCallback = () => singleton.signinCallback().then(user => setState(user?.state));
export const renew = () => singleton.signinSilent().then(assertAuthenticated).catch(login);
export const getUser = () => singleton.getUser();
export const getAuthenticatedUser = () => getUser().then(assertAuthenticated).catch(renew);
export const getAccessToken = () => getAuthenticatedUser().then(u => u.access_token);
export const logout = async (remoteLogout: boolean = true) => {
  if (remoteLogout)
    try {
      const accessToken = await singleton.getUser().then(u => u?.access_token);
      await sendRemoteLogoutRequest(accessToken);
    } catch {
      /* ignore remote logout failure */
    }
  await singleton.revokeTokens();
  await singleton.removeUser();
  await singleton.clearStaleState();
  await singleton.signoutRedirect();
  return await waitForever();
};

export const getAccessTokenExpiringNotificationTimeInSeconds = () =>
  singleton.settings.accessTokenExpiringNotificationTimeInSeconds;
