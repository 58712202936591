/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import React from 'react';
import { FullScreenErrorPage } from './FullScreenErrorPage';

export class ErrorBoundary extends React.Component {
  children: React.ReactNode;
  state: { error?: any };
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = {};
  }
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({ error });
  }
  render() {
    return this.state.error ? (
      // TODO - What should the user see  for unhandled errors?
      // (this is the last resort)
      <FullScreenErrorPage />
    ) : (
      <>{this.props.children}</>
    );
  }
}
