/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import { assigned } from './assigned';
import { inherited } from './inherited';
import { internal } from './internal';
import { IRoleAssignmentMin } from './roleAssignment';
import { IAssignableAccessRole } from './typesAndConstants';

export type IPreAuthorization = {
  inherited: IAssignableAccessRole[];
  assigned: IAssignableAccessRole[];
  internal: IAssignableAccessRole[];
};

export function getPreAuthorization(roleAssignments: IRoleAssignmentMin[], authorizationPaths: string[]): IPreAuthorization {
  return {
    inherited: inherited(roleAssignments, authorizationPaths),
    assigned: assigned(roleAssignments, authorizationPaths),
    internal: internal(roleAssignments, authorizationPaths),
  };
}
