/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import React from 'react';
import './FullScreenErrorPage.scss';
import { ErrorPage } from './components/errorpage';

export function FullScreenErrorPage({...props}) {
  return (
    <div className='full-screen-error'>
      <ErrorPage {...props}/>
    </div>
  );
}
