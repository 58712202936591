/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import { useCallback, useState } from 'react';

export function useActionBlocker() {
  const [busy, setBusy] = useState(false);
  const actionBlocker = useCallback(
    <T>(f: () => Promise<T>) =>
      busy
        ? undefined
        : async () => {
            setBusy(true);
            return await f().finally(() => setBusy(false));
          },
    [busy]
  );
  return actionBlocker;
}
