/*
 * Copyright 2024 Tridium Inc. All rights reserved.
 */

import { niagaraHttp } from '../utils/niagaraHttp';
import { ILicensesDTO } from './dto';

export function getLicensesAsync(regCode: string) {
  return niagaraHttp.get<ILicensesDTO>(`${window.ENV.API_BASE_URL}/api/v2/devicemanagement/registration/${regCode}`);
}

export function getLicensesForDeviceReRegistrationAsync(regCode: string, deviceID: string) {
  return niagaraHttp.get<ILicensesDTO>(
    `${window.ENV.API_BASE_URL}/api/v2/devicemanagement/reregistration/${regCode}/deviceUuid/${deviceID}`
  );
}
