/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import singleton from './userManager';
import { User } from 'oidc-client-ts';

export function onUserChange(callback: (user: User | null | void) => void) {
  singleton.events.addUserLoaded(callback);
  singleton.events.addUserUnloaded(callback);
  const onExpired = () => void singleton.getUser().then(callback);
  singleton.events.addAccessTokenExpired(onExpired);
  singleton.events.addAccessTokenExpiring(onExpired);
  return () => {
    singleton.events.removeUserLoaded(callback);
    singleton.events.removeUserUnloaded(callback);
    singleton.events.removeAccessTokenExpired(onExpired);
    singleton.events.removeAccessTokenExpiring(onExpired);
  };
}
